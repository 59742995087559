<template>
  <b-modal
    ref="modal"
    title="Edit Geolocation"
    modal-class="geo-location-editor"
    ok-title="Confirm location"
    no-close-on-backdrop
    centered
    v-model="showModal"
    :ok-disabled="!isPositionSet"
    @shown="shown"
    @ok="confirm"
    @cancel="cancel"
    @hide="cancel"
  >
    <form-group label="Search by address" class="search">
      <b-form-input v-model="address" />
      <b-btn variant="primary" @click="search" :disabled="searchDisabled">
        <font-awesome-icon icon="search" />
        Search
      </b-btn>
    </form-group>
    <b-button-group class="marker-controls">
      <b-btn variant="primary" @click="markCenter">Mark center</b-btn>
      <b-btn variant="secondary" @click="clearMarker">Clear marker</b-btn>
    </b-button-group>

    <b-alert v-if="isPolygon && !polygonDrawingEnabled" show class="polygon-info-bar">
      <font-awesome-icon icon="circle-info" />
      Polygon geo fences are natively supported on Fareclock app versions 3.4 and later.
      The Fareclock cloud also enforces polygon geo fences for all app versions when the device is online and when post-processing offline punches.
      The circle drawn around the polygon is automatically used for older versions of the mobile app that do not support polygon geofences.
    </b-alert>
    <b-alert v-else-if="!isPolygon && !polygonDrawingEnabled" show class="polygon-info-bar">
      <font-awesome-icon icon="circle-info" />
      Click <font-awesome-icon icon="draw-polygon" /> button in map below to draw a non-circular polygon geofence instead of a circle.
    </b-alert>

    <GmvMap
      ref="mapRef"
      :center="center"
      :zoom="7"
      :options="mapOptions"
      map-type-id="hybrid"
      class="gmap"
    >
      <div class="custom-controls">
        <div class="custom-control object-control" ref="circleControl">
          <button type="button" :id="`object-button-${$.uid}`" @click="drawCircle">
            <font-awesome-icon :icon="['fas', 'circle-dot']" />
          </button>
        </div>
        <div :class="['custom-control', 'object-control', 'polygon-control', polygonDrawingEnabled ? 'enabled' : null]" ref="polygonControl">
          <button type="button" :id="`object-button-${$.uid}`" @click="drawPolygon">
            <font-awesome-icon :icon="['fas', 'draw-polygon']" />
          </button>
        </div>
      </div>

      <GmvMarker
        v-if="markerPosition && !polygonDrawingEnabled"
        :position="markerPosition"
        :draggable="!isPolygon"
        @position_changed="onMarkerPositionChanged"
      />
      <GmvCircle
        v-if="markerPosition && !polygonDrawingEnabled"
        :center="markerPosition"
        :radius="fenceRange"
        :draggable="!isPolygon"
        :editable="!isPolygon"
        :fillColor="polygonOptions.fillColor"
        :fillOpacity="polygonOptions.fillOpacity"
        :strokeColor="polygonOptions.strokeColor"
        :strokeWeight="polygonOptions.strokeWeight"
        @center_changed="onCircleCenterChanged"
        @radius_changed="onCircleRadiusChanged"
      />
      <GmvPolygon
        v-if="polygonPaths.length > 0 && !polygonDrawingEnabled"
        :paths="polygonPaths"
        :editable="true"
        :fillColor="polygonOptions.fillColor"
        :fillOpacity="polygonOptions.fillOpacity"
        :strokeColor="polygonOptions.strokeColor"
        :strokeWeight="polygonOptions.strokeWeight"
        @paths_changed="onPolygonPathsChanged"
      />

      <GmvDrawingManager
          v-if="polygonDrawingEnabled"
          ref="drawingManager"
          :polygon-options="polygonOptions"
          :shapes="drawingShapes"
          :drawingControl="drawingControlEnabled"
          :drawingMode="drawingMode"
          @update:shapes="drawingShapesUpdated"
      />

    </GmvMap>

    <form-group class="geo-fence-range" label="Geofence range">
      <vue-slider
        v-model="fenceRangeSliderValue"
        :min="0"
        :max="4"
        :interval=".001"
        tooltip='always'
        :tooltip-formatter="fenceRangeDisplay"
        :disabled="isPolygon"
      />
    </form-group>
  </b-modal>
</template>
<script>
import { defineAsyncGmapComponent, GOOGLE_MAPS_MAP_ID } from './google-maps'
import Button from 'primevue/button'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import VueSlider from '@/components/vue-slider-component'
import { mapGetters } from 'vuex'
import { metersToSliderValue, sliderValueToMeters, smallestEnclosingCircle } from '@/utils/geo'
import { toRaw } from 'vue'

const DefaultZoomLevel = 1
const FocusedZoomLevel = 16
const MinimumFenceRange = 10 // never allow less than 10 meters

export default {
  name: 'GeoLocationEditor',
  components: {
    Button,
    HelpTextIcon,
    VueSlider,
    GmvMap: defineAsyncGmapComponent('GmvMap'),
    GmvMarker: defineAsyncGmapComponent('GmvMarker'),
    GmvCircle: defineAsyncGmapComponent('GmvCircle'),
    GmvPolygon: defineAsyncGmapComponent('GmvPolygon'),
    GmvDrawingManager: defineAsyncGmapComponent('GmvDrawingManager')
  },
  props: {
    modelValue: Boolean, // visible
    location: {
      type: Object,
      required: true
    }
  },
  data () {

    let resolveMounted
    const mountedPromise = new Promise(resolve => {
      resolveMounted = resolve
    })

    return {
      showModal: false,
      address: null,
      center: { lat: 0, lng: 0 },
      markerPosition: null,
      polygonPaths: [],
      fenceRange: 100,
      mountedPromise,
      resolveMounted,
      mapOptions: {
        scaleControl: true,
        mapId: GOOGLE_MAPS_MAP_ID,
        controlSize: 20
      },
      polygonDrawingEnabled: false,
      drawingShapes: [],
      drawingMode: null,
      drawingControlEnabled: false,
      polygonOptions: {
        fillColor: '#2ecc71',
        fillOpacity: 0.3,
        strokeWeight: 3,
        strokeColor: '#27ae60',
        draggable: true,
        editable: true,
        clickable: true
      }
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatDistance']),
    isPositionSet () {
      return !!this.markerPosition && !!this.address
    },
    fenceRangeSliderValue: {
      get () {
        return metersToSliderValue(this.fenceRange)
      },
      set (v) {
        this.fenceRange = sliderValueToMeters(v)
      }
    },
    fenceRangeDisplay () {
      return this.formatDistance(this.fenceRange)
    },
    searchDisabled () {
      return !this.address
    },
    isPolygon () {
      return this.polygonPaths.length > 0
    }
  },
  watch: {
    modelValue () {
      this.showModal = this.modelValue
    }
  },
  methods: {
    async getMap () {
      await this.$gmapApiPromiseLazy()
      let mapPromise = this.$refs.mapRef?.mapPromise
      while (!mapPromise) {
        await new Promise(resolve => setTimeout(resolve, 50))
        mapPromise = this.$refs.mapRef?.mapPromise
      }
      const map = await mapPromise
      return map
    },
    async initMap () {
      const map = await this.getMap()

      // Create custom controls.
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.$refs.circleControl)
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.$refs.polygonControl)
    },
    async clearMap () {
      // Remove custom controls.
      const map = await this.getMap()
      if (map) {
        map.controls[google.maps.ControlPosition.RIGHT_TOP].clear()
      }
    },
    async shown () {

      await this.initMap()

      this.address = this.location.fenceAddress
      this.fenceRange = this.location.fenceRange
      this.polygonPaths = this.location.fenceVertices?.map(v => new google.maps.LatLng(...v)) ?? []

      const isPositionSetInModel = this.location.fenceLat || this.location.fenceLng
      const zoom = isPositionSetInModel ? FocusedZoomLevel : DefaultZoomLevel

      this.$nextTick(() => {
        this.getMap().then(map => {
          if (isPositionSetInModel) {
            this.placeMarkerOnMap(
              new window.google.maps.LatLng(
                this.location.fenceLat,
                this.location.fenceLng
              ),
              map
            )
          } else {
            map.setCenter(new window.google.maps.LatLng(0, 0))
          }

          map.setOptions({
            zoom,
            streetViewControl: false,
            mapTypeControl: true
          })

          // TODO: Since updating to bootstrap-vue version 2.1.0, the google map has not
          // TODO: rendered properly when reopening the modal after closing. I've tried all
          // TODO: sorts of tricks, but nothing is working, including resize:
          // this.$refs.mapRef.$gmapDefaultResizeBus.$emit('resize')
          // window.google.maps.event.trigger(map, 'resize')
        })
      })
    },
    search () {
      const geocoder = new window.google.maps.Geocoder()

      // By default, we search by address.
      // But if the search string looks like lat/lng coordinates, then we'll search for that instead.
      let geocoderRequest = { address: this.address }
      if (this.address) {
        let testCoordinates = this.address.replaceAll(/\s/g, '').replaceAll(String.fromCharCode(176), '').split(',')
        if (testCoordinates.length === 2) {
          const lat = parseFloat(testCoordinates[0])
          const lng = parseFloat(testCoordinates[1])
          if (!isNaN(lat) && !isNaN(lng)) {
            geocoderRequest = {
              location: { lat, lng }
            }
          }
        }
      }

      geocoder.geocode(geocoderRequest, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location
          const address = results[0].formatted_address // Google-verified address

          this.placeMarker(location)
          this.address = address
          this.setAddressForMarkerPosition() // get latest address for position

          this.getMap().then(map => {
            if (map.getZoom() < FocusedZoomLevel) map.setZoom(FocusedZoomLevel)
          })
        } else {
          // TODO: display error
        }
      })
    },
    setAddressForMarkerPosition () {
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode({ location: this.markerPosition }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.address = results[0].formatted_address
        } else {
          // TODO: display error
        }
      })
    },
    placeMarker (latLng) {
      this.getMap().then(map => {
        this.placeMarkerOnMap(latLng, map)
      })
    },
    placeMarkerOnMap(latLng, map) {
      this.markerPosition = latLng
      map.panTo(this.markerPosition)
    },
    markCenter () {
      this.getMap().then(map => {
        this.markerPosition = map.getCenter()
        this.setAddressForMarkerPosition()
      })
    },
    clearMarker () {
      this.markerPosition = null
      this.address = null
    },
    onPositionChanged (latLng) {
      // Google Maps seems to interact better if the two-way binding runs in next tick
      this.$nextTick(() => {
        this.placeMarker(latLng)
        this.setAddressForMarkerPosition()
        // TODO: Move polygon.
      })
    },
    onMarkerPositionChanged (latLng) {
      this.onPositionChanged(latLng)
    },
    onCircleCenterChanged (latLng) {
      this.onPositionChanged(latLng)
    },
    onCircleRadiusChanged (newRadius) {
      // Google Maps only updates properly if two-way binding runs in next tick
      this.$nextTick(() => {
        this.fenceRange = Math.max(MinimumFenceRange, Math.round(newRadius))
      })
    },
    onPolygonPathsChanged (paths) {
      // console.log('onPolygonPathsChanged', paths)
      this.polygonPaths = paths.getArray()[0].getArray()
      this.fitCircleToPolygon()
    },
    confirm () {
      if (!this.isPositionSet) return // ignore

      Object.assign(this.location, {
        fenceLat: this.markerPosition.lat(),
        fenceLng: this.markerPosition.lng(),
        fenceAddress: this.address,
        fenceRange: this.fenceRange,
        fenceVertices: this.polygonPaths.map(p => [p.lat(), p.lng()])
      })

      this.clearMarker()

      this.close()
    },
    cancel () {
      this.close()
    },
    close () {
      this.$emit('update:modelValue', false)
      this.clearMap()
      this.polygonDrawingEnabled = false
    },
    drawCircle () {
      this.drawingShapes = []
      this.polygonPaths = []
      this.polygonDrawingEnabled = false
    },
    async drawPolygon () {
      this.drawingShapes = []
      this.polygonPaths = []
      this.polygonDrawingEnabled = true
      await this.$nextTick()
      await this.$nextTick()
      this.drawingControlEnabled = false
      this.drawingMode = 'polygon'
    },
    drawingShapesUpdated (shapes) {
      // console.log('drawingShapesUpdated', shapes)
      this.polygonDrawingEnabled = false
      this.polygonPaths = shapes[0].overlay.getPath().getArray()
      this.fitCircleToPolygon()
    },
    fitCircleToPolygon () {
      const circle = smallestEnclosingCircle(this.polygonPaths.map(path => ([path.lat(), path.lng()])))
      this.onMarkerPositionChanged({ lat: circle.center[0], lng: circle.center[1] })
      this.fenceRange = Math.round(circle.radius)
    }
  },
  mounted () {
    this.resolveMounted()
  },
  beforeDestroy () {
    this.close()
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.geo-location-editor {
  .search {
    > :deep(div) {
      display: flex;

      @include media-breakpoint-only(xs) {
        flex-wrap: wrap;
      }

      > * {
        margin: 5px;
      }
    }
    input {
      max-width: 325px;
    }
  }

  .polygon-info-bar {
    margin-top: 10px;
  }

  .marker-controls {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 10px;
    }
  }

   .gmap {
    margin-top: 10px;
    width: 100%;
    height: 250px;
  }

  .geo-fence-range {
    margin-top: 1.5rem;

    :deep(.vue-slider) {
      // prevent slider tooltip from overlapping with field label
      margin-top: 2.5rem;
    }
  }

  .custom-control {

    button {
      padding: 1px 2px;
      border-radius: 2px;
      border-color: transparent;
    }
    svg {
      width: 12px;
      height: 12px;
    }

    &.object-control {
      // Google's positioning doesn't look good, so fix it.
      right: 5px !important;
    }
    &.polygon-control {
      margin-top: 3px;
      &.enabled {
        button {
          background-color: gold;
        }
      }
    }
  }
}

.gmap {
  // Hide drawing controls.
  :deep(div.gmnoprint[role="menubar"]:nth-child(2)) {
    display: none;
  }
}

</style>
