<template>
  <div :class="['date-time-picker', 'date-time-picker', readonly ? 'readonly' : null]">
    <ejs-datetimepicker
      ref="picker"
      v-model="forwardValue"
      :format="sfDateTimeFormat"
      :timeFormat="sfTimeFormat"
      :allowEdit="!readonly"
      :readonly="readonly"
      :showClearButton="!readonly"
      @blur="onBlur"
    />
    <div v-if="showTimezone" :class="['timezone-label', 'timezone-label']">{{ timezoneLabel }}</div>
  </div>
</template>
<script>
import { DateTimePickerComponent } from '@syncfusion/ej2-vue-calendars'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
import { modelDateTimeFormat } from '@/utils/misc'
import '@/utils/sync-fusion-license'

export default {
  name: 'DateTimePicker',
  inheritAttrs: false,
  components: {
    'ejs-datetimepicker': DateTimePickerComponent
  },
  props: {
    modelValue: String,
    timezone: String,
    readonly: Boolean,
    showTimezone: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters('formatPreferences', ['dateTimeFormat','sfDateTimeFormat', 'sfTimeFormat']),
    ...mapState({
      orgTimezone: 'timezone'
    }),
    useTimezone () {
      return this.timezone || this.orgTimezone
    },
    timezoneLabel () {
      const m = moment.tz(this.modelValue, this.useTimezone)
      if (!m.isValid()) return ''
      // zoneAbbr() seems to be either an alphabetic abbreviation such as 'EST',
      // or a number such as '+08' (Asia/Singapore). In the latter case,
      // we don't want to display "+08 +08:00".
      const hasAbbr = isNaN(Number.parseInt(m.zoneAbbr()))
      return m.format(hasAbbr ? "z Z" : 'Z')
    },
    forwardValue: {
      get () {
        return this.modelValue
          // Pass in naive datetime string to Date constructor so that same wall time is used,
          // since Date uses system timezone.
          ? new Date(moment.tz(this.modelValue, this.useTimezone).format('YYYY-MM-DDTHH:mm:ss'))
          : null
      },
      set (v) {
        // The ejs-daterangepicker passes in a native date object localized to system time.
        // So we need to replace the timezone with the configured one, without changing the
        // wall time.
        // TODO: Dedupe?
        this.$emit('update:modelValue', v
          ? moment.tz(moment(v).format('YYYY-MM-DD HH:mm:ss'), this.useTimezone).format(modelDateTimeFormat)
          : null
        )
      }
    }
  },
  methods: {
    // The SyncFusion TimePicker component doesn't parse lowercase am/pm, so we need to do that ourselves.
    // https://www.syncfusion.com/forums/163648/datetimepicker-not-parsing-lower-case-am-pm
    // We'll do it as soon as the user types it, because there's no ambiguities dealing with valid partial date-time strings.
    cleanInput (event) {
      const target = event.target
      const value = target.value.replace(/am/i, 'AM').replace(/pm/i, 'PM')
      if (target.value !== value) target.value = value
    },
    // On blur, we'll do full date-time parse to allow for more lenient parsing formats than what SyncFusion allows, e.g.,
    // 24 hour time when the display format is 12 hour. First we'll do strict parsing according to expected SyncFusion format
    // based on org format preference, and if that is not valid then we'll try lenient parsing.
    onBlur (event) {
      // this.dateTimeFormat is the format that SyncFusion outputs but using moment's format syntax.
      let m = moment(event.model.inputElement.value, this.dateTimeFormat)
      if (!m.isValid()) m = moment(event.model.inputElement.value)
      if (m.isValid()) {
        const value = event.model.globalize.formatDate(m.toDate(), { format: this.sfDateTimeFormat })
        event.model.value = value
      }
    }
  },
  mounted () {
    const el = this.$refs.picker.$el
    if (el) el.addEventListener('input', this.cleanInput)
  },
  beforeDestroy () {
    const el = this.$refs.picker.$el
    if (el) el.removeEventListener('input', this.cleanInput)
  }
}
</script>
<style>
/* TODO: Combine with DateRangePicker styles */
@import '@syncfusion/ej2-base/styles/fabric.css';
@import '@syncfusion/ej2-buttons/styles/fabric.css';
@import '@syncfusion/ej2-inputs/styles/fabric.css';
@import '@syncfusion/ej2-popups/styles/fabric.css';
@import '@syncfusion/ej2-lists/styles/fabric.css';
@import "@syncfusion/ej2-vue-calendars/styles/fabric.css";
</style>
<style lang="scss" scoped>
.date-time-picker {
  display: flex;
  flex-direction: row;

  :deep(.e-datetime-wrapper) {
    .e-datetimepicker {
      min-width: 150px !important;
    }
  }

  .timezone-label {
    margin-top: 5px;
    margin-left: .5rem;
  }
}
</style>
