<template>
  <OverlayPanel ref="op" appendTo="body" class="date-range-dialog" :dismissable="false" :baseZIndex="baseZIndex">
    <div class="date-range-dialog-content">
      <div class="presets">
        <template v-for="(preset, index) in presets" :key="index">
          <button  :class="['preset', { 'preset-selected': preset.value === formValue.preset }]"
            @click="onSelectPreset(preset)">
            {{ preset.label }}
          </button>
          <hr v-if="preset.breakAfter" class="preset-break">
        </template>
      </div>

      <div class="main-pane">
        <div class="title">Date range:</div>
        <div :class="['inputs', enableTime ? 'time-enabled' : 'time-disabled']">
          <Calendar inputClass="no-border" class="start-calendar" v-model="startDate"
            :dateFormat="pvDateFormat" />

          <DateRangeTimePicker v-if="enableTime" v-model="startTime" :increment="timeIncrement" :format="timeFormat" />

          <div class="separator">&mdash;</div>
          <Calendar inputClass="no-border" class="end-calendar" v-model="endDate"
            :dateFormat="pvDateFormat" />

          <DateRangeTimePicker v-if="enableTime" v-model="endTime" :increment="timeIncrement" :format="timeFormat" />
        </div>
        <div class="calendars">
          <Calendar
            ref="multiCalendar"
            inline
            selectionMode="range"
            v-model="dateRange"
            :numberOfMonths="2"
            :dateFormat="pvDateFormat"
            class="multi-calendar"
          />
          <OverlayPanel ref="monthYearOp" appendTo="body" class="month-year-overlay" :dismissable="false">
            <Calendar ref="monthYearCalendar" :view="monthYearView" inline
              @date-select="onDateSelect" />
          </OverlayPanel>
        </div>
        <div class="footer">
          <div :class="['selected', { 'opacity-0': dayCount < 1 }]">
            Selected {{ dayCount }} day{{ dayCount > 1 ? 's' : '' }}
          </div>
          <div class="buttons">
            <Button label="SELECT" class="p-button-sm p-button-text" :disabled="!isValid" @click="commitChanges" />
            <Button label="CANCEL" class="p-button-sm p-button-text p-button-secondary" @click="cancelChanges" />
          </div>
        </div>
      </div>
    </div>
  </OverlayPanel>
</template>

<script>
import moment from 'moment-timezone'
import Button from "primevue/button"
import Calendar from "primevue/calendar"
import OverlayPanel from "primevue/overlaypanel"
import DateRangeTimePicker from './DateRangeTimePicker.vue'
import { modelDateFormat as DATE_MODEL_FORMAT, modelTimeFormat as TIME_MODEL_FORMAT } from '@/utils/misc'

// Override library disabling month/year pickers in multi-month mode.
// https://github.com/primefaces/primevue/issues/5532
// We also have to override the click handlers themselves in a watcher below.
// FIXME: not working in primevue 3
Calendar.computed.switchViewButtonDisabled = function () {
  return this.disabled
}

export default {
  name: 'DateRangeDialog',
  components: {
    Button,
    Calendar,
    OverlayPanel,
    DateRangeTimePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    visible: Boolean,
    presets: {
      type: Array,
      required: true
    },
    target: {
      type: HTMLDivElement
    },
    dateFormat: String,
    timeFormat: String,
    timeIncrement: Number,
    startKey: String,
    endKey: String,
    timezone: String,
    enableTime: Boolean,
    baseZIndex: Number
  },
  data() {
    return {
      formValue: {},
      monthYearView: null
    }
  },
  computed: {
    dayCount() {
      return this.startDt && this.endDt ? this.endDt.diff(this.startDt, 'days') + 1 : 0
    },
    startDt() {
      const startDt = this.formValue?.[this.startKey]
      return startDt ? moment.tz(startDt, this.timezone) : null
    },
    endDt() {
      const endDt = this.formValue?.[this.endKey]
      if (!endDt) return null
      const isDateOnly = moment(endDt, DATE_MODEL_FORMAT, true).isValid()
      return moment.tz(endDt, this.timezone).add(this.enableTime && isDateOnly ? 1 : 0, 'days')
    },
    startDate: {
      get () {
        return this.startDt?.format(this.dateFormat)
      },
      set (v) {
        // TODO: Should we preserve startTime when changing date, or reset to 12:00 AM?
        this.formValue[this.startKey] = moment.tz(v, this.pvDateFormat, this.timezone).startOf('day').format(this.enableTime ? null : DATE_MODEL_FORMAT)
      }
    },
    endDate: {
      get () {
        return this.endDt ? moment(this.endDt).subtract(this.enableTime ? 1 : 0, 'minute').format(this.dateFormat) : null
      },
      set (v) {
        // TODO: Should we preserve endTime when changing date, or reset to 11:59 PM?
        let date = moment.tz(v, this.pvDateFormat, this.timezone).startOf('day')
        this.formValue[this.endKey] = date.add(this.enableTime ? 1 : 0, 'day').format(this.enableTime ? null : DATE_MODEL_FORMAT)
      }
    },
    startTime: {
      get () {
        return this.startDt?.format(TIME_MODEL_FORMAT)
      },
      set (v) {
        const time = moment(v, TIME_MODEL_FORMAT)
        this.formValue[this.startKey] = moment(this.startDt).hours(time.hours()).minutes(time.minutes()).format()
      }
    },
    endTime: {
      get () {
        return this.endDt ? this.endDt.subtract(1, 'minute').format(TIME_MODEL_FORMAT) : null
      },
      set (v) {
        const time = moment(v, TIME_MODEL_FORMAT)
        this.formValue[this.endKey] = moment(this.endDt).subtract(1, 'minute').hours(time.hours()).minutes(time.minutes()).add(1, 'minute').format()
      }
    },
    dateRange: {
      get () {
        const startDt = this.startDt?.toDate() ?? null
        const endDt = this.endDt?.toDate() ?? null
        return startDt || endDt ? [startDt, endDt] : null
      },
      set (v) {
        const startDate = v[0] ? moment(v[0]).tz(this.timezone, true) : null
        const endDate = v[1] ? moment(v[1]).tz(this.timezone, true) : null
        this.formValue[this.startKey] = startDate ? startDate.startOf('day').format(this.enableTime ? null : DATE_MODEL_FORMAT) : null
        this.formValue[this.endKey] = endDate ? endDate.add(this.enableTime ? 1 : 0, 'day').format(this.enableTime ? null : DATE_MODEL_FORMAT) : null
      }
    },
    pvDateFormat () {
      return this.dateFormat.replace(/YY/g, 'y').replace(/MM/g, 'm').replace(/DD/g, 'd')
    },
    pvTimeFormat () {
      return this.timeFormat
    },
    isValid () {
      if (!this.startDt || !this.endDt) return false
      const mStart = moment(this.startDt)
      const mEnd = moment(this.endDt)
      if (!mStart.isValid() || !mEnd.isValid()) return false
      return this.enableTime ?  mStart.isBefore(mEnd) :  mStart.isSameOrBefore(mEnd)
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        // Make working copy of value for editing until user clicks SELECT to commit value.
        this.formValue = { ...this.value }
        this.$refs.op.show({ currentTarget: this.target })
        setTimeout(() => {
          this.$refs.multiCalendar.switchToMonthView = this.switchToMonthView
          this.$refs.multiCalendar.switchToYearView = this.switchToYearView
          this.$refs.multiCalendar.$forceUpdate()
        }, 100)
      } else {
        this.$refs.op.hide()
      }
    },
    startDt () {
      this.onDateRangeChange()
    },
    endDt () {
      this.onDateRangeChange()
    }
  },
  methods: {
    changeVisible(visible) {
      this.$emit('update:visible', visible)
    },
    commitChanges() {
      Object.assign(this.value, this.formValue)
      this.changeVisible(false)
      this.$emit('commit')
    },
    cancelChanges() {
      this.changeVisible(false)
    },
    onSelectPreset(preset) {
      // The following code is pretty much the same as DateRangePicker's created() function.
      const range = preset.factory()
      this.formValue[this.startKey] = range[0].format(this.enableTime ? null: DATE_MODEL_FORMAT)
      this.formValue[this.endKey] = range[1].subtract(this.enableTime ? 0 : 1, 'days').format(this.enableTime ? null : DATE_MODEL_FORMAT)
      this.formValue.preset = preset.value
    },
    switchToMonthView() {
      this.switchToView('month')
    },
    switchToYearView() {
      this.switchToView('year')
    },
    switchToView(view) {
      this.monthYearView = view
      this.$refs.monthYearOp.show({ currentTarget: this.$el.querySelector('.inputs') })
    },
    onDateSelect (event) {
      // console.log('onDateSelect', event)
      if (this.monthYearView === 'year') {
        this.monthYearView = 'month'
      } else {
        // Navigate main calendar.
        const m = moment(event)
        this.$refs.multiCalendar.currentMonth = m.month()
        this.$refs.multiCalendar.currentYear = m.year()

        this.monthYearView = null
        this.$refs.monthYearOp.hide()
      }
      // Set internal currentView value to avoid error that PrimeVue throws, seemingly due to a bug.
      this.$refs.monthYearCalendar.currentView = this.monthYearView
    },
    onDateRangeChange () {
      this.setMatchingPreset()
      // PrimeVue is not navigating to displayed calendar months for entire range as we want.
      // So we'll do that programmatically.
      // Unfortunately though, PrimeVue can only display consecutive months, so we'll just show beginning of range.
      // https://github.com/orgs/primefaces/discussions/1637
      // Only do this navigation after entire range is selected, although it seems PrimeVue does this anyway.
      if (this.startDt && this.endDt && this.$refs.multiCalendar) {
        // Delay update until after PrimeVue tries its own incorrect navigation.
        this.$nextTick(() => {
          this.$refs.multiCalendar.currentMonth = this.startDt.month()
          this.$refs.multiCalendar.currentYear = this.startDt.year()
        })
      }
    },
    setMatchingPreset () {
      for (const preset of this.presets) {
        const presetRange = preset.factory()
        if (this.startDt && moment(this.startDt).isSame(presetRange[0]) &&
            this.endDt && moment(this.endDt).add(this.enableTime ? 0 : 1, 'days').isSame(presetRange[1])) {
          this.formValue.preset = preset.value
          return
        }
      }
      this.formValue.preset = 'custom'
    }
  }
}
</script>

<style lang="scss">
.date-range-dialog {
  width: 700px;

  .p-overlaypanel-content {
    padding-left: 0;
  }

  .date-range-dialog-content {
    display: flex;

    .presets {
      margin-right: 10px;
      max-width: 115px;

      // Prevent preset list from getting to tall, especially if showing future presets.
      max-height: 425px;
      overflow-x: auto;
      overflow-y: auto;

      .preset {
        background: none;
        border: none;
        padding: 10px;
        padding-left: .75rem;
        color: grey;
        width: 100%;
        text-align: left;
        cursor: pointer;
        border-left: 3px solid transparent;
        font-size: 14px;
        text-wrap: nowrap;

        &:hover {
          background-color: #eee;
        }

        &.preset-selected {
          border-left-color: #3296dc;
        }
      }

      .preset-break {
        background-color: #ccc;
        height: 1px;
        border: 0;
      }
    }

    .main-pane {
      display: flex;
      flex-direction: column;
      border-left: 1px solid lightgrey;
      padding-left: 10px;
      width: 100%;

      .title {
        color: grey;
        font-size: .9rem;
        margin-top: 7px;
        margin-bottom: 5px;
      }

      .inputs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 10px;

        input:enabled:focus,
        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: none !important;
        }

        .p-calendar {
          width: 105px;
          margin-right: 10px;
          border: none;
          border-bottom: 1px solid lightgrey;

          input {
            border: none !important;
          }
        }

        .time-picker {
          width: 115px;
          border: none;
          border-bottom: 1px solid lightgrey;
          margin-right: 2px;
          margin-left: 5px;

          input {
            border: none !important;
          }

          .p-dropdown-label {
            padding-right: 0px;
          }

          .p-dropdown-trigger {
            padding-left: 0px;
          }
        }

        .separator {
          margin-left: 12px;
          margin-right: 7px;
          width: 25px;
          color: grey;
        }

        input {
          font-size: .9rem !important;
        }

        &.time-disabled {
          justify-content: space-around;

          .p-calendar, .separator {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      .calendars {
        .multi-calendar {
          width: 100%;

          td {
            padding: .25rem;

            span {
              border-radius: 50%;
              font-size: 15px;
              width: 1.85rem !important;
              height: 1.85rem !important;
            }

            // PrimeVue 2 has a bug that showOtherMonths/selectOtherMonths is not implemented correctly.
            // It should be fixed in PrimeVue 3.
            // https://github.com/primefaces/primevue/issues/4099
            // In the meantime, use css to workaround it.
            // &.p-datepicker-other-month {
            //   span {
            //     visibility: hidden;
            //   }
            // }
          }
        }
      }

      .footer {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .selected {
          font-size: .8rem;
          color: grey;
          margin-left: 5px;
        }

        .buttons {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>

<style scoped>
/* Integrate all the following styles into the previous scss style block. */

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: red !important;
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
  border: none;
}

.p-inputtext {
  border: none !important;
  margin: 0;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-calendar .p-inputtext,
.p-datepicker table {
  font-size: 13px;
}
</style>
