<template>
  <!-- TODO: Do we need this? ==> v-mask="extendedMaskOptions" -->
  <b-modal ref="modal"
    v-bind="$attrs"
    title="Convert to Punch"
    ok-only
    ok-title="Skip"
    ok-variant="secondary"
    :ok-disabled="saving"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    @show="onShow"
  >
    <p class="question">
      <code>{{ employeeName }}</code> did not clock IN or OUT.
      Would you like to convert this clock log at <code>{{ localDate }}</code> to a punch?
    </p>

    <b-table :fields="fields" :items="punches" foot-clone>
      <template v-slot:cell(in)="data">
        <span v-if="data.item.inDt">{{ formatDate(data.item.inDt) }}</span>
        <b-button v-else-if="data.item.inEligible" variant="primary"
          :disabled="v$.$invalid || saving"
          @click="assignAsIn(data.item.id)">
          <font-awesome-icon icon="circle-plus" /> ASSIGN AS IN
        </b-button>
      </template>
      <template v-slot:cell(out)="data">
        <span v-if="data.item.outDt">{{ formatDate(data.item.outDt) }}</span>
        <b-button v-else-if="data.item.outEligible" variant="primary"
          :disabled="saving"
          @click="assignAsOut(data.item.id)">
          <font-awesome-icon icon="circle-plus" /> ASSIGN AS OUT
        </b-button>
      </template>
      <template v-slot:foot(in)>
        <b-button variant="primary" @click="createNewIn" :disabled="v$.$invalid || saving">
          <font-awesome-icon icon="circle-plus" /> NEW IN PUNCH
        </b-button>
      </template>
      <template v-slot:foot(out)>
        <b-button variant="primary" @click="createNewOut" :disabled="saving">
          <font-awesome-icon icon="circle-plus" /> NEW OUT PUNCH
        </b-button>
      </template>
    </b-table>

    <template v-if="costingEnabled">
      <hr>
      <div class="costing-selection-header">
        <div class="costing-selection-title">Costing Selection</div>
        (for IN punch)
      </div>
      <costing-selection
        :value="$data"
        :worker="worker"
        :deviceOrgUnit="deviceOrgUnit"
        :department="department"
        :userLabels="userLabels"
        @validityChanged="costingSelectionValid = $event"
        invalidMessage="This selection is not valid. It is only required for an IN punch."
        :enablePartialCosting="false"
        :enableJobRequiredValidation="true"
      />
    </template>

    <template v-if="errorMessage">
      <hr>
      <div class="error">
        <font-awesome-icon icon="triangle-exclamation" />
        <span>{{ errorMessage }}</span>
      </div>
    </template>

  </b-modal>
</template>
<script>
import moment from 'moment-timezone'
import service from './services/ClockLogService'
import Maskable from '@/mixins/Maskable'
import CostingSelection from '@/views/settings/costing/CostingSelection.vue'
import { mapGetters } from 'vuex'
import { extractErrorMessage } from '@/utils/misc'
import { useVuelidate } from '@vuelidate/core'
import { useModalController } from 'bootstrap-vue-next'

function cleanData () {
  return {
    fields: [
      { key: 'in', label: 'IN' },
      { key: 'out', label: 'OUT' }
    ],
    punches: [],
    clockLogId: 0,
    date: null,
    timezone: null,
    worker: null,
    employeeName: null,
    deviceOrgUnit: null,
    department: null,
    userLabels: null,
    job: null,
    jobCategory: null,
    jobPhase: null,
    costCode: null,
    costingSelectionValid: true,
    saving: false,
    errorMessage: null
  }
}

/* TEST CODE
  $vm0.show({
    employeeName: 'John Doe',
    clockLogId: 1,
    date: moment().toISOString(),
    timezone: 'America/New_York',
    punches: [{
      id: 2,
      inDt: moment().subtract(3, 'hours').toISOString(),
      outDt: moment().subtract(2, 'hours').toISOString()
    }, {
      id: 3,
      inDt: moment().subtract(1, 'hours').toISOString(),
    }]
  })
*/

export default {
  setup () {
    return {
      v$: useVuelidate(),
      hideModal: useModalController().hide
    }
  },
  name: 'ConvertPunchModal',
  inheritAttrs: false,
  mixins: [Maskable],
  components: {
    CostingSelection
  },
  props: {
    initialData: Object
  },
  data: cleanData,
  computed: {
    ...mapGetters(['costingEnabled']),
    localDate () {
      return this.formatDate(this.date)
    },
    extendedMaskOptions () {
      return this.maskOptions
        ? Object.assign({ selector: '.modal-content' }, this.maskOptions)
        : false
    }
  },
  methods: {
    onShow () {
      const initialData = this.initialData
      this.markEligiblePunches(initialData.punches, initialData.date)
      Object.assign(this.$data, cleanData(), initialData)
    },
    close () {
      this.hideModal()
    },
    formatDate (date) {
      return date ? moment(date).tz(this.timezone).format('lll') : null
    },
    markEligiblePunches (punches, clockLogDateString) {
      if (!punches) return

      const clockLogDate = moment(clockLogDateString)
      let cursorDate = null

      punches.forEach((punch, index) => {
        const nextPunch = punches[index + 1]
        const nextDate = nextPunch ? moment(nextPunch.inDt || nextPunch.outDt) : null
        const inDt = punch.inDt ? moment(punch.inDt) : null
        const outDt = punch.outDt ? moment(punch.outDt) : null

        if ((!cursorDate || cursorDate.isSameOrBefore(clockLogDate)) &&
            (!nextDate || clockLogDate.isSameOrBefore(nextDate))) {
          if (!inDt && clockLogDate.isSameOrBefore(outDt)) {
            punch.inEligible = true
          } else if (!outDt && inDt.isSameOrBefore(clockLogDate)) {
            punch.outEligible = true
          }
        }

        cursorDate = moment(punch.outDt || punch.inDt)
      })
    },
    attachPunch (punchId, punchType) {
      if (this.saving) return

      this.saving = true
      this.errorMessage = null

      // TODO: Fix mask not working, or just remove it.
      this.showUpdatingMask()

      const costingSelection = {
        job: this.job,
        jobCategory: this.jobCategory,
        jobPhase: this.jobPhase,
        costCode: this.costCode
      }

      service.attachPunch(this.clockLogId, punchId, punchType, costingSelection)
        .then(() => {
          this.$emit('converted-to-punch', { punchType })
          this.hideMask()
          this.close()
          this.$toast.success('Successfully converted to punch')
        })
        .catch(error => {
          this.errorMessage = extractErrorMessage(error)
          // TODO: Fix mask not working, or just remove it.
          this.showErrorMask(error)
        })
        .finally(() => { this.saving = false })
    },
    assignAsIn (punchId) {
      this.attachPunch(punchId, 'in')
    },
    assignAsOut (punchId) {
      this.attachPunch(punchId, 'out')
    },
    createNewIn () {
      this.attachPunch('new', 'in')
    },
    createNewOut () {
      this.attachPunch('new', 'out')
    }
  },
  validations: {
    costingSelectionValid: {
      childValid: function () { return !!this.costingSelectionValid }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.question {
  text-align: left;
}

.fa {
  margin-right: 5px;
}

.costing-selection-header {
  display: flex;
  flex-direction: row;

  .costing-selection-title {
    text-decoration: underline;
    margin-bottom: 1rem;
    margin-right: .25rem;
  }
}

.error {
  color: $flat-ui-alizarin;
  svg {
    margin-right: .5rem;
  }
}
</style>
