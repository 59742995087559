<template>
  <b-modal
    v-bind="$attrs"
    :title="`${contactTypeName} Contact for ${name}`"
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    centered
    modal-class="message-contact-model"
    @ok="save"
    ok-title="Save"
    :busy="saving"
  >
      <h6>{{ contactTypeName }}: {{ contact.uri }}</h6>
      <b-form-checkbox v-model="isSuppressed" :disabled="saving">
        OPT-OUT of All {{ contactTypeName }}
      </b-form-checkbox>
      <template v-if="contact.type === 'email'">
        <b-form-checkbox v-model="billingEmailSuppressed" :disabled="saving || isSuppressed">
          OPT-OUT of Billing emails
        </b-form-checkbox>
        <b-form-checkbox v-model="deviceAlertEmailSuppressed" :disabled="saving || isSuppressed">
          OPT-OUT of Device Alert emails
        </b-form-checkbox>
        <b-form-checkbox v-model="disciplineAlertEmailSuppressed" :disabled="saving || isSuppressed">
          OPT-OUT of Attendance Alert emails
        </b-form-checkbox>
        <b-form-checkbox v-model="reportEmailSuppressed" :disabled="saving || isSuppressed">
          OPT-OUT of Report emails
        </b-form-checkbox>
      </template>
      <form-group label="Suppression count" label-cols="auto">
        <b-form-input
          v-model="suppressionCount"
          number
          type="number"
          min="0"
          :disabled="saving"
        />
      </form-group>
      <div v-if="errorMessage" class="error-message" key="error-message">
        <font-awesome-icon icon="triangle-exclamation" />
        <span>{{ errorMessage }}</span>
      </div>
    </b-modal>
</template>
<script>
import _ from 'lodash'
import service from '@/views/manage/messaging/messageService'
import { extractErrorMessage } from '@/utils/misc'
import { useModalController } from 'bootstrap-vue-next'

const BILLING_EMAIL_GROUP = '24574'
const DEVICE_ALERT_EMAIL_GROUP = '25032'
const DISCIPLINE_ALERT_EMAIL_GROUP = '28601'
const REPORT_EMAIL_GROUP = '24366'

export default {
  name: 'MessageContactModal',
  inheritAttrs: false,
  setup () {
    return {
      hideModal: useModalController().hide
    }
  },
  props: {
    name: String,
    contact: Object
  },
  data () {
    return {
      saving: false,
      errorMessage: null,
      isSuppressed: false,
      billingEmailSuppressed: false,
      deviceAlertEmailSuppressed: false,
      disciplineAlertEmailSuppressed: false,
      reportEmailSuppressed: false,
      suppressionCount: 0,
    }
  },
  computed: {
    contactTypeName () {
      return _.capitalize(this.contact.type)
    },
    unsubscribeGroups () {
      const unsubscribeGroups = []
      if (this.contact.type === 'email') {
        if (this.billingEmailSuppressed) unsubscribeGroups.push(BILLING_EMAIL_GROUP)
        if (this.deviceAlertEmailSuppressed) unsubscribeGroups.push(DEVICE_ALERT_EMAIL_GROUP)
        if (this.disciplineAlertEmailSuppressed) unsubscribeGroups.push(DISCIPLINE_ALERT_EMAIL_GROUP)
        if (this.reportEmailSuppressed) unsubscribeGroups.push(REPORT_EMAIL_GROUP)
      }
      return unsubscribeGroups
    }
  },
  watch: {
    suppressionCount () {
      if (!_.isInteger(this.suppressionCount)) {
        setTimeout(() => {
          this.suppressionCount = _.toInteger(this.suppressionCount)
        })
      }
    }
  },
  methods: {
    save (bvEvt) {
      bvEvt.preventDefault()

      this.saving = true
      this.errorMessage = null
      service.updateContactByUri(this.contact.type, this.contact.uri, {
        is_suppressed: this.isSuppressed,
        unsubscribe_groups: this.unsubscribeGroups,
        suppression_count: this.suppressionCount
      })
        .then(contact => {
          this.$emit('saved', contact)
          this.close()
        })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.saving = false })
    },
    close () {
      this.hideModal()
    }
  },
  mounted () {
    this.isSuppressed = this.contact.isSuppressed
    this.billingEmailSuppressed = this.contact.type === 'email' && this.contact.unsubscribeGroups.includes(BILLING_EMAIL_GROUP)
    this.deviceAlertEmailSuppressed = this.contact.type === 'email' && this.contact.unsubscribeGroups.includes(DEVICE_ALERT_EMAIL_GROUP)
    this.disciplineAlertEmailSuppressed = this.contact.type === 'email' && this.contact.unsubscribeGroups.includes(DISCIPLINE_ALERT_EMAIL_GROUP)
    this.reportEmailSuppressed = this.contact.type === 'email' && this.contact.unsubscribeGroups.includes(REPORT_EMAIL_GROUP)
    this.suppressionCount = this.contact.suppressionCount
  }
}
</script>
<style lang="scss">
.message-contact-model {
  .error-message {
    svg {
      margin-right: 5px;
    }
  }
}
</style>
