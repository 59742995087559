<template>
  <b-modal
    ref="modal"
    :title="`Send notification to ${orgUserName}`"
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    centered
    visible
    modal-class="send-notification-modal"
    @ok="save"
    ok-title="Send"
    :ok-disabled="!valid"
    :busy="saving"
  >
  <form-group label="Title" label-cols="auto">
    <b-form-input
      v-model="title"
      :disabled="saving"
    />
  </form-group>
  <form-group label="Body" label-cols="auto">
    <b-form-input
      v-model="body"
      :disabled="saving"
    />
  </form-group>
  <div v-if="errorMessage" class="error-message" key="error-message">
    <font-awesome-icon icon="triangle-exclamation" />
    <span>{{ errorMessage }}</span>
  </div>
  </b-modal>
</template>
<script>
import _ from 'lodash'
import service from './services/EmployeeService'
import { extractErrorMessage } from '@/utils/misc'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'SendNotificationModal',
  setup () {
    return {
      hideModal: useModalController().hide
    }
  },
  props: {
    orgUserId: Number,
    orgUserName: String
  },
  data () {
    return {
      saving: false,
      errorMessage: null,
      title: null,
      body: null
    }
  },
  computed: {
    valid () {
      return !!this.title && !!this.body
    }
  },
  methods: {
    save (bvEvt) {
      bvEvt.preventDefault()

      this.saving = true
      this.errorMessage = null
      service.sendNotificationMessage(this.orgUserId, this.title, this.body)
        .then(() => { this.hideModal() })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.saving = false })
    }
  }
}
</script>
<style lang="scss">
.send-notification-modal {
  .error-message {
    svg {
      margin-right: 5px;
    }
  }
}
</style>
