<template>
  <b-modal
    v-bind="$attrs"
    no-trap
    title="Send Device Activation Link"
    ok-title="Close"
    ok-variant='secondary'
    ok-only
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    @shown="shown"
    @hidden="$emit('hidden', $event)"
  >
    <div class="message-title">
      Message:
    </div>
    <div class="message-container">
      <b-form-textarea ref="message" readonly no-resize
        :value="message" :rows="3" :max-rows="6" class="message"
      />
      <font-awesome-icon-with-title
        :icon="['far', 'copy']"
        class="copy-icon text-primary"
        :title="copyTitle"
        @click="copyLink"
      />
    </div>
    <div class="instruction">
      Copy the message above and send it yourself, or choose from one of the following options:
    </div>
    <ul class="list">
      <li v-if="email"><b-link href="#" @click="sendEmail" :disabled="sending || isAllEmailSuppressed">Send Email to {{ email }}</b-link></li>
      <li v-if="mobilePhone"><b-link href="#" @click="sendSms" :disabled="sending || isAllSmsSuppressed">Send SMS to {{ formattedPhone }}</b-link></li>
      <li v-if="mobilePhone && supportsSmsScheme"><b-link href="#" @click="sendMessages" :disabled="sending">Send on Messages to {{ formattedPhone }}</b-link></li>
      <li v-if="mobilePhone"><b-link href="#" @click="sendWhatsApp" :disabled="sending">Send on WhatsApp to {{ formattedPhone }}</b-link></li>
      <li v-if="mobilePhone"><b-link href="#" @click="sendViber" :disabled="sending">Send on Viber</b-link></li>
    </ul>
    <div class="feedback invalid-feedback d-block" v-if="isAllEmailSuppressed">
      <font-awesome-icon icon="triangle-exclamation" />
      This user is not able to receive emails. See "Notifications" section in user profile.
    </div>
    <div class="feedback invalid-feedback d-block" v-if="isAllSmsSuppressed">
      <font-awesome-icon icon="triangle-exclamation" />
      This user is not able to receive mobile text. See "Notifications" section in user profile.
    </div>
    <div class="feedback valid-feedback d-block" v-if="sending">
      <font-awesome-icon icon="circle-notch" spin /> Sending message...
    </div>
    <div class="feedback invalid-feedback d-block" v-else-if="error">
      <font-awesome-icon icon="triangle-exclamation" /> {{ error }}
    </div>
  </b-modal>
</template>
<script>
import { stripIndents } from 'common-tags'
import FontAwesomeIconWithTitle from '@/components/FontAwesomeIconWithTitle.vue'
import { mapGetters, mapState } from 'vuex'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import service from './services/EmployeeService'
import { extractErrorMessage } from '@/utils/misc'
import { detect as detectBrowser } from 'detect-browser'
import { useModalController } from 'bootstrap-vue-next'

const defaultCopyTitle = 'Copy link'
const copiedCopyTitle = 'Link copied'

const browser = detectBrowser()
const isMacOS = browser.os === 'Mac OS'

export default {
  name: 'PersonalDeviceSendLinkModal',
  setup () {
    return {
      hideModal: useModalController().hide
    }
  },
  inheritAttrs: false,
  components: {
    FontAwesomeIconWithTitle
  },
  data () {
    return {
      copyTitle: defaultCopyTitle,
      sending: false,
      error: null,
      // iOS and Android don't allow body text to be specified in sms: url scheme, but Mac OS does allow it.
      supportsSmsScheme: isMacOS
    }
  },
  props: ['orgUserId', 'installCode', 'mobilePhone', 'email', 'isAllEmailSuppressed', 'isAllSmsSuppressed'],
  computed: {
    ...mapState('userProfile', ['userName']),
    ...mapGetters('userProfile', ['isSudoUser']),
    message () {
      return stripIndents`
        ${this.senderName} has sent you a link to install Fareclock on your mobile device:
        https://fareclock.com/install/${this.installCode}
        Or if prompted, enter code: ${this.installCode}.
      `
    },
    senderName () {
      return this.isSudoUser ? 'Fareclock Support' : this.userName
    },
    formattedPhone () {
      if (!this.mobilePhone) return null
      return parsePhoneNumberFromString(this.mobilePhone).formatInternational()
    },
    messagesLink () {
      if (!this.mobilePhone) return null
      const text = encodeURIComponent(this.message)
      return `sms:${this.mobilePhone}&body=${text}`
    },
    whatsAppLink () {
      if (!this.mobilePhone) return null
      const phone = this.mobilePhone.substr(1)
      const text = encodeURIComponent(this.message)
      return `https://api.whatsapp.com/send?phone=${phone}&text=${text}`
    },
    viberLink () {
      // TODO: not working on iOS
      const text = encodeURIComponent(this.message)
      return `viber://forward?text=${text}`
    }
  },
  methods: {
    copyLink () {
      this.$refs.message.$el.select()
      document.execCommand('copy')

      this.copyTitle = copiedCopyTitle
      setTimeout(() => { this.copyTitle = defaultCopyTitle }, 2000)
    },
    shown () {
      this.error = null
      this.sending = false
      this.fitMessageHeight()
    },
    fitMessageHeight () {
      const el = this.$refs.message.$el
      el.style.height = `${el.scrollHeight}px`
    },
    sendEmail () {
      this.sendServerMessage('email')
    },
    sendSms () {
      this.sendServerMessage('sms')
    },
    sendServerMessage (medium) {
      this.error = null
      this.sending = true
      service.sendInstallLink(this.orgUserId, medium)
        .then(() => this.hide())
        .catch(error => { this.error = extractErrorMessage(error) })
        .finally(() => { this.sending = false })
    },
    sendMessages () {
      window.open(this.messagesLink, '_blank')
      this.hide()
    },
    sendWhatsApp () {
      window.open(this.whatsAppLink, '_blank')
      this.hide()
    },
    sendViber () {
      window.open(this.viberLink, '_blank')
      this.hide()
    },
    hide () {
      this.hideModal()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.message-container {
  display: flex;
  flex-direction: row;

  .message {
    // leave room for copy icon
    padding-right: 50px;
  }
  .copy-icon {
    position: relative;
    right: 2rem;
    top: .75rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
.instruction {
  margin-top: .75rem;
}
.list {
  margin-top: .25rem;

  a.disabled {
    color: $flat-ui-silver;
  }
}
</style>
