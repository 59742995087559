<template>
  <b-modal
    v-bind="$attrs"
    title="Confirm delete"
    ok-title="Delete"
    :ok-disabled="!confirmed"
    ok-variant="danger"
    hide-header-close
    no-close-on-backdrop
    centered
    no-trap
    @ok="ok"
  >
    <p>
      Deleting this User is permanent and cannot be undone.
      All of this user's associated information including punches and photos will also be deleted.
      <br/><br/>
      If this user is no longer active, it may be preferable to de-activate them rather than delete them.
      De-activated user data will not be deleted, and at the same time an inactive user does not count toward the total maximum user count for the subscription.
      To de-activate an user, simply uncheck the "Active" checkbox near the top of the form and save.
      <br/><br/>
      If you are sure you want to delete this User, type "DELETE" in the field below and click the "Delete" button.
    </p>
    <b-form-input v-model="typeDelete"></b-form-input>
  </b-modal>
</template>
<script>

export default {
  name: 'DeleteUserModal',
  inheritAttrs: false,
  emits: ['delete'],
  data () {
    return {
      typeDelete: null
    }
  },
  computed: {
    confirmed () {
      return this.typeDelete === 'DELETE'
    }
  },
  methods: {
    ok () {
      this.$emit('delete')
    }
  }
}
</script>
