<template>
  <div class="punch-form" role="tablist">
    <GlobalEvents
      :filter="filterGlobalEvents"
      @keyup.prevent.ctrl.a="approvePunch"
    />
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapsePunchInformation block :variant="v$.$validationGroups.punchInformationGroup.$invalid ? 'danger' : 'dark'">
          Punch Information
        </b-btn>
      </b-card-header>
      <b-collapse id="collapsePunchInformation" visible role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group v-bind="formGroupConfig" v-if="form.id">
                  <template #label>
                    <div>
                      Worker
                      <id-icon :id="form.employee" />
                    </div>
                  </template>
                  <b-button v-if="canEditWorkers" @click="openUserFormModal(form.employee)" variant="link">{{ workerName }}</b-button>
                  <b-form-input
                    v-else
                    v-model="workerName"
                    type="text"
                    readonly
                  />
                </form-group>
                <form-group v-else
                  :validator="v$.form.employee"
                  label="Worker"
                  v-bind="formGroupConfig"
                >
                  <template #default="slotProps">
                    <worker-multiselect
                      v-bind="slotProps"
                      v-model="form.employee"
                      :contextId="form.id"
                      @fullValueChange="onWorkerSelected"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Status" class="status" v-bind="formGroupConfig">
                  <div class="vertical-center-form-field-text">
                    <font-awesome-icon icon="triangle-exclamation" v-if="form.status === 'flagged'" />
                    {{ _$.capitalize(form.status || 'new') }}
                  </div>
                  <b-button
                    v-if="approvable"
                    :disabled="processing"
                    @click="approvePunch"
                    variant="success"
                    size="sm"
                    class="approve"
                  >
                    <font-awesome-icon icon="circle-notch" spin v-if="approving" />
                    <font-awesome-icon icon="circle-check" v-else />
                    Approve
                  </b-button>
                  <b-button
                    v-if="form.id && canEditPunchTimes && !punchIsLocked"
                    :disabled="processing"
                    @click="deletePunch"
                    variant="danger"
                    size="sm"
                    class="delete not-printable"
                  >
                    <font-awesome-icon icon="trash-can" />
                    Delete
                  </b-button>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="sortedEntryStates.length > 0">
                <form-group label="Punch State" v-bind="formGroupConfig">
                  <key-multiselect
                    v-model="entryState"
                    label="name"
                    track-by="id"
                    select-label=""
                    deselect-label=""
                    :options="sortedEntryStates"
                  />
                  <div v-if="!addingNew && entryState != originalData.entryState" class="entry-state-buttons">
                    <b-button
                      @click="saveEntryState"
                      :disabled="processing"
                      variant="primary"
                      class="action save"
                      size="sm"
                    >
                      <font-awesome-icon :icon="savingStateChange ? 'circle-notch' : 'circle-check'" :spin="savingStateChange" />
                      Save State Change
                    </b-button>
                    <b-button
                      @click="cancelEntryState"
                      :disabled="processing"
                      variant="secondary"
                      class="action save"
                      size="sm"
                    >
                      <font-awesome-icon icon="circle-xmark" />
                      Cancel
                    </b-button>
                  </div>
                </form-group>
                <div class="entry-state-set-by" v-if="originalData.entryStateSetBy">Last set by {{ originalData.entryStateSetBy }}</div>
              </b-col>
              <b-col cols="12" sm="6" v-if="form.department">
                <form-group v-bind="formGroupConfig">
                  <template #label>
                    <div>
                      Department
                      <id-icon :id="form.department" />
                    </div>
                  </template>
                  <b-form-input
                    v-model="form.departmentName"
                    type="text"
                    readonly
                  />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="form.orgUnit && !singleOrgUnit">
                <form-group v-bind="formGroupConfig">
                  <template #label>
                    <div>
                      Org Unit
                      <id-icon :id="form.orgUnit" />
                    </div>
                  </template>
                  <b-form-input
                    v-model="form.orgUnitName"
                    type="text"
                    readonly
                  />
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <div class="in-out-header">IN</div>
                <div v-if="originalData.inClockLog && originalData.inClockLog.tampered" class="tampered">
                  <font-awesome-icon icon="triangle-exclamation" />
                  Time Tampered
                </div>
                <form-group label="Date/Time" v-bind="formGroupConfig"
                  :validator="v$.form.inDt"
                >
                  <template #default="slotProps">
                    <!-- TODO: replace with something else -->
                    <date-time-picker
                      v-bind="slotProps"
                      v-model="form.inDt"
                      :timezone="inOrgUnit && inOrgUnit.timezone"
                      :readonly="!canEditPunchTimes || punchIsLocked"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.inOrgUnit" label="Org Unit" v-bind="formGroupConfig" v-if="!singleOrgUnit">
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.inOrgUnit"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :options="inOrgUnits"
                      :allow-empty="false"
                      :disabled="punchIsLocked"
                      @fullValueChange="inOrgUnit = $event"
                    />
                  </template>
                </form-group>
                <form-group label="Punch Code" v-bind="formGroupConfig" v-if="punchCodesEnabled">
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.inPunchCode"
                      label="name"
                      track-by="name"
                      select-label=""
                      deselect-label=""
                      :disabled="punchIsLocked"
                      :options="sortedInPunchCodes"
                      :allow-empty="false"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.notes" label="Notes" v-bind="formGroupConfig">
                    <template #default="slotProps">
                      <b-form-textarea
                        v-bind="slotProps"
                        v-model="form.notes"
                        :rows="3"
                        :disabled="punchIsLocked"
                      />
                    </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <div class="in-out-header">OUT</div>
                <div v-if="originalData.outClockLog && originalData.outClockLog.tampered" class="tampered">
                  <font-awesome-icon icon="triangle-exclamation" />
                  Time Tampered
                </div>
                <form-group label="Date/Time" v-bind="formGroupConfig"
                  :validator="v$.form.outDt"
                >
                  <template #default="slotProps">
                    <date-time-picker
                      v-bind="slotProps"
                      v-model="form.outDt"
                      :timezone="outOrgUnit && outOrgUnit.timezone"
                      :readonly="!canEditPunchTimes || punchIsLocked"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.outOrgUnit" label="Org Unit" v-bind="formGroupConfig" v-if="!singleOrgUnit">
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.outOrgUnit"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :options="outOrgUnits"
                      :allow-empty="false"
                      :disabled="punchIsLocked"
                      @fullValueChange="outOrgUnit = $event"
                    />
                  </template>
                </form-group>
                <form-group label="Punch Code" v-bind="formGroupConfig" v-if="punchCodesEnabled">
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.outPunchCode"
                      label="name"
                      track-by="name"
                      select-label=""
                      deselect-label=""
                      :disabled="punchIsLocked"
                      :options="sortedOutPunchCodes"
                      :allow-empty="false"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1" v-show="costingEnabled">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseCostingInformation block :variant="v$.$validationGroups.costingInformationGroup.$invalid ? 'danger' : 'dark'">
          Costing Information
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseCostingInformation" visible role="tabpanel">
        <b-card-body v-if="costingEnabled">
          <b-container>
            <b-row>
              <b-col cols="12">
                <costing-selection
                  :value="form"
                  :worker="form.employee"
                  :orgUnit="form.orgUnit"
                  :deviceOrgUnit="form.inOrgUnit"
                  :department="form.department"
                  :userLabels="userLabels"
                  :customFieldValues="indexedSupplementalUserCustomFieldValues"
                  @validityChanged="costingSelectionValid = $event"
                  @jobChanged="onJobChanged"
                  :enablePartialCosting="false"
                  :enableJobRequiredValidation="true"
                  :formGroupConfig="formGroupConfig"
                  :disabled="punchIsLocked"
                />
                <b-btn v-if="!addingNew && !punchIsLocked" variant="outline-primary" size="sm" class="not-printable" @click="distributeCosting" :disabled="processing || formDirty || !totalRawMinutes">
                  <font-awesome-icon icon="chart-network" />
                  Distribute Costing
                </b-btn>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1" v-show="hasCustomFields">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseCustomFields block :variant="v$.$validationGroups.customFieldsGroup.$invalid ? 'danger' : 'dark'">
          Custom Fields
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseCustomFields" visible role="tabpanel">
        <b-card-body v-if="hasCustomFields">
          <b-container>
            <b-row>
              <b-col cols="12" sm="6" v-if="punchCustomFields.length > 0">
                <custom-field-inputs
                  appliesTo="punch"
                  :showEndCondition="!!form.outDt"
                  :enableUserConditions="true"
                  :orgUser="form.employee"
                  :orgUnit="form.orgUnit"
                  :department="form.department"
                  :userLabels="userLabels"
                  :userCustomFieldValues="indexedSupplementalUserCustomFieldValues"
                  :job="form.job"
                  :workLabels="job && job.labels"
                  v-model="form.customFieldValues"
                  @validityChanged="customFieldValuesValid = $event"
                />
              </b-col>
              <b-col cols="12" sm="6" v-if="formattedSupplementalUserCustomFieldValues.length > 0">
                <div class="supplemental-user-custom-field-values">
                  <div class="title">Worker Values</div>
                  <div v-for="([name, value], index) in formattedSupplementalUserCustomFieldValues" :key="index" class="item">
                    <div class="name">{{ name }}</div>
                    <div class="value">{{ value }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1" v-show="hasFaceInfo">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseFaceInformation block variant="dark">
          Face Information
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseFaceInformation" visible role="tabpanel">
        <b-card-body v-if="hasFaceInfo">
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <div class="in-out-header">IN</div>
                <clock-log-face-result v-if="originalData.inClockLog"
                  :value="originalData.inClockLog"
                  :formGroupConfig="formGroupConfig"
                  :travelDistance="originalData.travelDistance"
                />
              </b-col>
              <b-col cols="12" sm="6">
                <div class="in-out-header">OUT</div>
                <clock-log-face-result v-if="originalData.outClockLog"
                  :value="originalData.outClockLog"
                  :formGroupConfig="formGroupConfig"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1" v-show="hasGeoInfo">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseGeoInformation block variant="dark">
          Geo Information
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseGeoInformation" visible role="tabpanel">
        <b-card-body v-if="hasGeoInfo">
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <div class="in-out-header">IN</div>
                <clock-log-geo-result v-if="originalData.inClockLog"
                  :value="originalData.inClockLog"
                  :formGroupConfig="formGroupConfig"
                />
              </b-col>
              <b-col cols="12" sm="6">
                <div class="in-out-header">OUT</div>
                <clock-log-geo-result v-if="originalData.outClockLog"
                  :value="originalData.outClockLog"
                  :formGroupConfig="formGroupConfig"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1" v-show="form.id">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseAdvanced block variant="dark">
          Advanced
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseAdvanced" visible role="tabpanel">
        <b-card-body v-if="form.id">
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <div class="in-out-header">IN</div>
                <punch-advanced-info v-if="originalData.inOrig"
                  :value="originalData"
                  action="in"
                  :formGroupConfig="formGroupConfig"
                />
                <form-group label="Override By" v-if="originalData.overrideBy"
                  v-bind="formGroupConfig"
                >
                  <div class="vertical-center-form-field-text">
                    {{ originalData.overrideBy }}
                  </div>
                </form-group>
                <form-group label="Shift Summary" v-if="scheduleEnabled && canEditPunchTimes"
                  v-bind="formGroupConfig"
                >
                  <div class="vertical-center-form-field-text">
                    <b-button v-if="originalData.shiftCard" @click="viewShiftSummary" variant="link" style="padding:0">View</b-button>
                    <template v-else>
                      None
                      <b-btn
                        v-if="canEditPunchTimes"
                        variant="outline-primary" size="sm" class="attach-to-shift not-printable"
                        @click="attachToShift" :disabled="processing || formDirty"
                      >
                        <font-awesome-icon icon="circle-notch" spin v-if="attachingToShift" />
                        <font-awesome-icon icon="calendar-plus" v-else />
                        Attach to Shift
                      </b-btn>
                    </template>
                  </div>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <div class="in-out-header">OUT</div>
                <punch-advanced-info v-if="originalData.outOrig"
                  :value="originalData"
                  action="out"
                  :formGroupConfig="formGroupConfig"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import IdIcon from '@/components/IdIcon.vue'
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, requiredIf } from '@vuelidate/validators'
import ClockLogFaceResult from '@/views/manage/clocklogs/ClockLogFaceResult.vue'
import ClockLogGeoResult from '@/views/manage/clocklogs/ClockLogGeoResult.vue'
import CostingSelection from '@/views/settings/costing/CostingSelection.vue'
import CustomFieldInputs from '@/components/CustomFieldInputs.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import WorkerMultiselect from '@/components/WorkerMultiselect.vue'
import PunchAdvancedInfo from './PunchAdvancedInfo.vue'
import { dateTime, dateTimeMinValue } from '@/utils/validators'
import crudService from './services/PunchService'
import ShiftSummaryModal from '@/views/manage/timecards/ShiftSummaryModal.vue'
import { extractErrorMessage, hasValue, modelDateTimeFormat } from '@/utils/misc'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import moment from 'moment-timezone'
import { punchRawMinutes, punchPaidWorkedMinutes } from './util'
import { getSupplementalUserCustomFieldValues, getSupplementalUserCustomFieldValue } from '@/utils/customFields'
import { GlobalEvents } from 'vue-global-events'
import { openUserFormModal } from '@/views/settings/employees/userFormModal'
import { useModalController } from 'bootstrap-vue-next'

const NewItem = () => ({
  employee: null,
  department: null,
  departmentName: null,
  orgUnit: null,
  orgUnitName: null,
  inDt: null,
  outDt: null,
  inOrgUnit: null,
  outOrgUnit: null,
  notes: null,
  job: null,
  jobCategory: null,
  jobPhase: null,
  costCode: null,
  customFieldValues: [],
  labels: []
})

export default {
  name: 'PunchForm',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true }),
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  mixins: [DetailBasedForm],
  components: {
    CustomFieldInputs,
    ClockLogFaceResult,
    ClockLogGeoResult,
    CostingSelection,
    DateTimePicker,
    GlobalEvents,
    IdIcon,
    KeyMultiselect,
    PunchAdvancedInfo,
    WorkerMultiselect
  },
  data () {
    return {
      formGroupConfig: {
        'label-cols': 12,
        'label-cols-md': 12,
        'label-cols-lg': 12,
        'label-cols-xl': 4,
      },
      form: NewItem(),
      inOrgUnit: null,
      outOrgUnit: null,
      costingSelectionValid: true,
      loadingDetail: false,
      approving: false,
      attachingToShift: false,
      savingStateChange: false,
      entryState: null,
      customFieldValuesValid: true,
      job: null
    }
  },
  computed: {
    ...mapState(['timezone']),
    ...mapGetters(['accessAllOrgUnits', 'canEditPunchTimes', 'canEditWorkers', 'costingEnabled', 'hasFaceFeature', 'geoEnabled', 'entryStateEnabled', 'punchCodesEnabled', 'scheduleEnabled']),
    ...mapGetters('formatPreferences', ['formatDateTime', 'formatName']),
    ...mapGetters({
      entryStates: 'entryStates/sortedItems',
      orgUnits: 'orgUnits/sortedItems'
    }),
    ...mapGetters('punchCodes', ['sortedInPunchCodes', 'sortedOutPunchCodes']),
    ...mapGetters('customFields', ['supplementalUserCustomFields', 'punchCustomFields', 'userCustomFields']),
    sortedEntryStates () {
      if (!this.entryStateEnabled) return []
      return this.entryStates('name').filter(item => item.active || item.id === this.originalData.entryState)
    },
    sortedOrgUnits () {
      return this.orgUnits('name').filter(item => item.active)
    },
    // In order to handle the case where the admin has org unit access to the worker's org unit but not to the device org units,
    // we need to add the in/outOrgUnits to the selection boxes for those fields.
    // This will incidentally also handle the case where the org unit was deactivated.
    inOrgUnits () {
      let orgUnits = this.sortedOrgUnits
      if (this.originalData.inOrgUnit && !orgUnits.some(i => i.id === this.originalData.inOrgUnit)) {
        orgUnits = _.sortBy(orgUnits.concat([{
          id: this.originalData.inOrgUnit,
          name: this.originalData.inOrgUnitName
        }]), 'name')
      }
      return orgUnits
    },
    outOrgUnits () {
      let orgUnits = this.sortedOrgUnits
      if (this.originalData.outOrgUnit && !orgUnits.some(i => i.id === this.originalData.outOrgUnit)) {
        orgUnits = _.sortBy(orgUnits.concat([{
          id: this.originalData.outOrgUnit,
          name: this.originalData.outOrgUnitName
        }]), 'name')
      }
      return orgUnits
    },
    workerName () {
      return this.form ? this.formatName(
        this.form.employeeFirstName, this.form.employeeLastName, this.form.employeeMiddleName, this.form.employeeDisplayName
      ) : null
    },
    singleOrgUnit () {
      return this.accessAllOrgUnits && this.sortedOrgUnits.length === 1
    },
    formInvalid () {
      return this.v$.$invalid
    },
    hasCustomFields () {
      return this.$store.getters.hasCustomFieldFeature &&
        !!this.formData.employee &&
        (this.supplementalUserCustomFields.length > 0 || this.punchCustomFields.length > 0)
    },
    hasFaceInfo () {
      return Boolean(
        this.hasFaceFeature &&
        this.form.id &&
        (
          _.get(this.form, 'inClockLog.faceMode') ||
          _.get(this.form, 'outClockLog.faceMode')
        )
      )
    },
    hasGeoInfo () {
      return Boolean(
        this.geoEnabled &&
        this.form.id &&
        (
          _.get(this.form, 'inClockLog.geoEnabled') ||
          _.get(this.form, 'outClockLog.geoEnabled')
        )
      )
    },
    processing () {
      return this.saving || this.approving || this.attachingToShift || this.savingStateChange
    },
    totalRawMinutes () {
      return punchRawMinutes(this.originalData)
    },
    totalPaidWorkedMinutes () {
      return punchPaidWorkedMinutes(this.originalData)
    },
    punchIsLocked () {
      if (this.addingNew || !this.entryStateEnabled) return false
      const entryStateId = this.originalData.entryState
      if (!entryStateId) return false
      const entryState = this.sortedEntryStates.find(entryState => entryState.id === entryStateId)
      return !entryState || entryState.readOnly
    },
    userLabels () {
      return (this.form?.labels ?? [])
        .filter(label => label.startsWith('user:'))
        .map(label => parseInt(label.replace(/^user:/, '')))
    },
    formattedSupplementalUserCustomFieldValues () {
      return this.supplementalUserCustomFields.map(customField => {
        const value = getSupplementalUserCustomFieldValue(customField, this.originalData.employee, this.supplementalData)
        return [customField.name, value]
      })
    },
    indexedSupplementalUserCustomFieldValues () {
      const values = getSupplementalUserCustomFieldValues(this.originalData.employee, this.supplementalData) || []
      const userCustomFieldIds = new Set(this.userCustomFields.map(f => f.id))
      return values.filter(value => userCustomFieldIds.has(value.field) && value.index)
    },
    approvable () {
      return this.form.id && this.form.status === 'flagged' && !this.punchIsLocked
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          const formData = NewItem()
          this.formDataChanged(formData)
        } else {
          // Auto-set IN/OUT org units to worker's org unit.
          if (!newValue.inOrgUnit) newValue.inOrgUnit = newValue.orgUnit
          if (!newValue.outOrgUnit) newValue.outOrgUnit = newValue.orgUnit
          // Localize IN/OUT datetimes, and remove seconds in order to prevent incorrectly
          // marking datetimes as dirty fields when bound to date time picker.
          // If org units not loaded yet, then we won't have timezone. Really, we need to run this computation again when org units load.
          if (newValue.inDt) {
            const timezone =  _.get(this.sortedOrgUnits.find(ou => ou.id === newValue.inOrgUnit), 'timezone') || newValue.timezone
            newValue.inDt = moment.tz(newValue.inDt, timezone).seconds(0).milliseconds(0).format(modelDateTimeFormat)
          }
          if (newValue.outDt) {
            const timezone =  _.get(this.sortedOrgUnits.find(ou => ou.id === newValue.outOrgUnit), 'timezone') || newValue.timezone
            newValue.outDt = moment.tz(newValue.outDt, timezone).seconds(0).milliseconds(0).format(modelDateTimeFormat)
          }
          this.formDataChanged(_.cloneDeep(newValue))
          this.considerRetrievingDetail('inClockLog')
          this.entryState = this.originalData.entryState
        }
      },
      immediate: true
    },
    formData: {
      handler (newValue) {
        this.form = newValue
      },
      immediate: true
    },
    formInvalid: {
      handler (value) {
        if (this.formInvalidChanged) this.formInvalidChanged(value)
      },
      immediate: true
    },
    'formData.orgUnit' (orgUnit) {
      if (orgUnit) {
        // If org units not loaded yet, then we won't have timezone. Really, we need to run this computation again when org units load.
        const timezone = _.get(this.sortedOrgUnits.find(ou => ou.id === orgUnit), 'timezone')
        if (timezone) this.formData.timezone = timezone
      }
      if (!this.formData.inOrgUnit) this.formData.inOrgUnit = orgUnit
      if (!this.formData.outOrgUnit) this.formData.outOrgUnit = orgUnit
    },
    entryState (entryState) {
      if (!this.punchIsLocked) this.formData.entryState = entryState
    }
  },
  methods: {
    considerRetrievingDetail (propertyHint) {
      if (propertyHint in this.originalData) return
      const detailIndex =  this.detailIndex
      // TODO: Clear loadingDetail if user changes navigates to different punch while loading.
      this.loadingDetail = true
      crudService.get(this.originalData.id)
        .then(data => {
          const punchData = data.results[0]
          this.detailUpdated(punchData, data.supplementalData, detailIndex)
        })
        .catch(error => {
          this.$toast.error(`Failed to load all punch detail due to: ${extractErrorMessage(error)}`)
        })
        .finally(() => this.loadingDetail = false)
    },
    viewShiftSummary () {
      this.showModal({
        component: ShiftSummaryModal,
        props: {
          shiftCardId: this.originalData.shiftCard
        }
      })
    },
    onWorkerSelected (worker) {
      this.form.orgUnit = this.form.inOrgUnit = this.form.outOrgUnit = worker?.orgUnit
      this.form.orgUnitName = worker?.orgUnitName
      this.form.department = worker?.department
      this.form.departmentName = worker?.departmentName
      this.form.labels = (worker?.labels || []).map(label => `user:${label}`)
      // Copy user's default custom punch field values to this punch.
      this.form.customFieldValues = (worker?.customFieldValues || [])
        .flatMap(customFieldValue => {
          const customField = this.punchCustomFields.find(cf => cf.id === customFieldValue.field)
          if (!customField || !hasValue(customFieldValue.value, true)) return []
          return [customFieldValue]
        })
    },
    approvePunch () {
      if (!this.approvable || this.processing) return
      const detailIndex =  this.detailIndex
      const punchData = this.originalData
      this.approving = true
      crudService.approve(this.form.id)
        .then(() => {
          punchData.status = 'approved'
          this.detailUpdated(punchData, null, detailIndex)
          this.formData.status = 'approved'
        })
        .catch(error => {
          this.$toast.error(`Failed to approve punch due to: ${extractErrorMessage(error)}`)
        })
        .finally(() => this.approving = false)
    },
    deletePunch () {
      this.eventBus.emit('deleteItem', this.form.id)
    },
    distributeCosting () {
      return import(/* webpackChunkName: "distribute-costing-modal" */ './DistributeCostingModal.vue')
        .then(module => {
          const DistributeCostingModal = module.default
          this.showModal({
            component: DistributeCostingModal,
            props: {
              punchId: this.originalData.id,
              totalRawMinutes: this.totalRawMinutes,
              totalPaidWorkedMinutes: this.totalPaidWorkedMinutes,
              originalCosting: this.originalData,
              onApplyGridTransaction: params => {
                this.applyGridTransaction(params)
                this.exit()
              }
            }
          })
        })
    },
    attachToShift () {
      const detailIndex =  this.detailIndex
      const originalData = this.originalData
      this.attachingToShift = true
      crudService.attachToShift(this.form.id)
        .then(data => {
          const punchData = data.results[0]
          originalData.shiftCard = punchData.shiftCard
          this.detailUpdated(originalData, null, detailIndex)
          this.formData.shiftCard = punchData.shiftCard
          // TODO: Need to emit event so that time card editor can display eyeball icon.
        })
        .catch(error => {
          this.$toast.error(`Failed to attach punch to shift due to: ${extractErrorMessage(error)}`)
        })
        .finally(() => this.attachingToShift = false)
    },
    saveEntryState () {
      this.savingStateChange = true
      const detailIndex =  this.detailIndex
      crudService.setEntryState(this.form.id, this.entryState)
        .then(data => {
          const punchData = data.results[0]
          this.detailUpdated(punchData, null, detailIndex)
          // TODO: Need to emit event so that time card editor can change locked state.
        })
        .catch(error => {
          this.$toast.error(`Failed to set punch state due to: ${extractErrorMessage(error)}`)
        })
        .finally(() => this.savingStateChange = false)
    },
    cancelEntryState () {
      this.entryState = this.originalData.entryState
    },
    filterGlobalEvents (event, handler, eventName) {
      // console.log('filterGlobalEvents', event)
      // We don't want the arrow/escape buttons to navigate when an input is in focus.
      return event.target.nodeName === 'BODY'
    },
    openUserFormModal (orgUserId) {
      openUserFormModal (orgUserId, this.showModal)
    },
    onJobChanged (job) {
      this.job = job
    }
  },
  validations () {
    const v = {
      $validationGroups: {
        punchInformationGroup: ['form.employee', 'form.inDt', 'form.outDt', 'form.inOrgUnit', 'form.outOrgUnit', 'form.notes'],
        costingInformationGroup: ['costingSelectionValid'],
        customFieldsGroup: ['form.customFieldValues']
      },
      form: {
        employee: {
          required
        },
        inDt: {
          required,
          dateTime
        },
        outDt: {
          dateTime,
          minValue: dateTimeMinValue(
            this.form.inDt,
            min => this.formatDateTime(min, this.outOrgUnit ? this.outOrgUnit.timezone : this.timezone),
            'OUT Date/Time'
          ),
        },
        inOrgUnit: {
          required
        },
        outOrgUnit: {
          required: requiredIf(() => !!this.form.outDt)
        },
        notes: {
          maxLength: maxLength(1000)
        },
        customFieldValues: {
          childValid: function () { return !!this.customFieldValuesValid }
        }
      },
      costingSelectionValid: {
        childValid: function () { return !!this.costingSelectionValid }
      }
    }
    return v
  },
  created () {
    this.$store.dispatch('orgUnits/load')
    if (this.entryStateEnabled) this.$store.dispatch('entryStates/load')
    if (this.punchCodesEnabled) this.$store.dispatch('punchCodes/load')
    if (this.$store.getters.hasCustomFieldFeature) this.$store.dispatch('customFields/load')
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/variables';

.punch-form {

  .in-out-header {
    font-weight: bold;
    font-size: .9rem;
    text-decoration: underline;
    margin: 0 0 .5rem 0;
  }

  .tampered {
    color: $flat-ui-alizarin;
    svg {
      margin-right: 5px;
    }
  }

  .vertical-center-form-field-text {
    padding-top: 5px;
  }

  .attach-to-shift {
    margin-left: 1rem;
  }

  .status {
    :deep(.form-row) {
      div.col {
        display: flex;
        flex-direction: row;
        align-items: center;
        >:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  .entry-state-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    .btn {
      margin-right: 10px;
    }
  }

  .entry-state-set-by {
    margin-top: -12px;
    margin-right: 15px;
    text-align: right;
    font-size: .7rem;
    font-style: italic;
  }

  :deep(.e-datetime-wrapper) {
    max-width: 15rem;
  }
  :deep(.costing-selection) {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: 50%;
      max-width: 50%;
    }

    @include media-breakpoint-down(md) {
      > * {
        flex: 100%;
        max-width: 100%;
      }
    }
  }

  .supplemental-user-custom-field-values {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      text-decoration: underline;
      margin-bottom: .25rem;
    }
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      .name {
        width: 50%;
        text-align: right;
        margin-right: 2rem;
      }
      .value {
        width: 50%;
        font-weight: bold;
      }
    }
  }
}
</style>
