<template>
  <panel :title="title" v-mask="maskOptions">
    <template #icons>
      <panel-header-icon icon="sync" @click="refresh" />
    </template>
    <chart-bar
      :options="chartOptions"
      :data="chartData"
      :width="chartWidth"
      :height="chartHeight"
    />
    <b-container class="pay-period-table">
      <b-row>
        <b-col class="summary" cols="12" sm="7">
          <template v-if="totalHours">
            {{ round(totalHours) }}
          </template>
          Total Employee Hours
        </b-col>
        <b-col class="review" cols="6" sm="2">
          <router-link :to="reviewHoursLink">review</router-link>
        </b-col>
        <b-col class="print" cols="6" sm="3">
          <router-link :to="printHoursLink">print report</router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="summary" cols="12" sm="7">
          <font-awesome-icon icon="triangle-exclamation" class="icon" />
          <template v-if="exceptionCount">
            {{ exceptionCount }}
          </template>
          {{ inflect('Exception', exceptionCount) }}
        </b-col>
        <b-col class="review" cols="6" sm="2">
          <router-link :to="reviewExceptionsLink">review</router-link>
        </b-col>
        <b-col class="print" cols="6" sm="3">
          <router-link :to="printExceptionsLink">print report</router-link>
        </b-col>
      </b-row>
      <b-row v-if="showClockLogFailures">
        <b-col class="summary" cols="12" sm="7">
          <font-awesome-icon icon="triangle-exclamation" class="icon" />
          <template v-if="clockLogFailureCount">
            {{ clockLogFailureCount }}
          </template>
          {{ inflect(clockLogFailureCount, 'Failure Requires', 'Failures Require') }} Action
        </b-col>
        <b-col class="review" cols="6" sm="2" v-if="canManageClockLogs">
          <router-link :to="reviewFailuresLink">review</router-link>
        </b-col>
      </b-row>
    </b-container>
  </panel>
</template>
<script>
import Panel from '@/components/Panel.vue'
import PanelHeaderIcon from '@/components/PanelHeaderIcon.vue'
import ChartBar from '@/components/ChartBar.vue'
import AutoRefresh from '@/mixins/AutoRefresh'
import Maskable from '@/mixins/Maskable'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
import MomentFormats from '@/utils/MomentFormats'
import service from './services/PayPeriodService'
import { inflect } from 'inflection'

const dateFormat = 'MMM DD'
const maxTicksLimit = 8

function getStepSize (periodDays) {
  if (periodDays <= 7) {
    return 1
  } else if (periodDays < 15) {
    return 2
  } else if (periodDays < 21) {
    return 3
  } else if (periodDays < 36) {
    return 7
  } else {
    return Math.ceil(periodDays / maxTicksLimit)
  }
}

export default {
  name: 'PayPeriod',
  mixins: [AutoRefresh, Maskable],
  components: {
    ChartBar,
    Panel,
    PanelHeaderIcon,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    whichPeriod: {
      type: String,
      required: true
    },
    showClockLogFailures: {
      type: Boolean,
      default: false
    },
    barBackgroundColor: {
      type: String,
      required: true
    },
    barBorderColor: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['timezone', 'payPeriodType', 'payPeriodStarts']),
    ...mapState('size', ['clientWidth', 'clientHeight']),
    ...mapGetters(['canManageClockLogs']),
    ...mapGetters({ getPayPeriodDateRange: 'payPeriodDateRange' }),
    payPeriodDateRange () {
      // Since payPeriodDateRange uses method-style access, and Vuex does not
      // react to changes to its dependencies, thus we need to declare the reactive
      // dependencies here.
      this.payPeriodType
      this.payPeriodStarts
      return this.getPayPeriodDateRange(this.whichPeriod, this.now)
    },
    formattedPayPeriodStartDate () {
      return moment(this.payPeriodDateRange[0]).format(MomentFormats.Iso8601Short)
    },
    formattedPayPeriodEndDate () {
      return moment(this.payPeriodDateRange[1]).subtract(1, 'day').format(MomentFormats.Iso8601Short)
    },
    periodDuration () {
      return moment.duration(this.payPeriodDateRange[1].diff(this.payPeriodDateRange[0]))
    },
    // TODO: chart-horizontal-bar needs to re-render when chartOptions changes.
    chartOptions () {
      const periodStart = this.payPeriodDateRange[0]
      const stepSize = getStepSize(this.periodDuration.asDays())

      return {
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            ticks: {
              stepSize,
              // not necessary to set maxTicksLimit because it was incorporated into stepSize
              // maxTicksLimit,
              callback: (label, index, labels) => {
                if (index < labels.length - 1) {
                  return moment(periodStart).add(label, 'days').format(dateFormat)
                }
              }
            }
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltips: {
            titleFont: { size: 0 },
            titleSpacing: 0,
            titleMarginBottom: 0,
            padding: {
              x: 10,
              y: 10
            },
            caretPadding: -10
          }
        }
      }
    },
    chartData () {
      const now = this.now
      const periodDays = this.periodDuration.asDays()
      const daysPast = this.payPeriodDateRange[1].isBefore(now)
        ? periodDays
        : moment.duration(now.diff(this.payPeriodDateRange[0])).asDays()

      return {
        // TODO: What to put for labels?
        labels: [''],
        datasets: [{
          label: 'Past',
          backgroundColor: this.barBackgroundColor,
          borderColor: this.barBorderColor,
          borderWidth: 1,
          data: [this.round(daysPast)]
        }, {
          label: 'Future',
          data: [this.round(periodDays - daysPast)],
          borderColor: this.barBorderColor,
          borderWidth: 1,
        }]
      }
    },
    chartHeight () {
      return this.clientWidth > 475 ? 75 : 60
    },
    chartWidth () {
      return this.clientWidth > 475 ? 350 : 275
    },
    reviewHoursLink () {
      return {
        name: 'punch-list',
        params: {
          view: JSON.stringify({
            from: this.formattedPayPeriodStartDate,
            to: this.formattedPayPeriodEndDate,
            preset: this.whichPeriod
          })
        }
      }
    },
    printHoursLink () {
      return {
        name: 'timecards-report',
        params: {
          view: JSON.stringify({
            commondates: this.whichPeriod,
            group: ['employeeName']
          })
        }
      }
    },
    reviewExceptionsLink () {
      return {
        name: 'punch-list',
        params: {
          view: JSON.stringify({
            status: 'exception',
            from: this.formattedPayPeriodStartDate,
            to: this.formattedPayPeriodEndDate,
            preset: this.whichPeriod
          })
        }
      }
    },
    printExceptionsLink () {
      return {
        name: 'raw-punches-report',
        params: {
          view: JSON.stringify({
            status: 'exception',
            commondates: this.whichPeriod,
            group: ['employeeName']
          })
        }
      }
    },
    reviewFailuresLink () {
      return {
        name: 'clock-log-list',
        params: {
          view: JSON.stringify({
            status: 'requires_action',
            from: this.formattedPayPeriodStartDate,
            to: this.formattedPayPeriodEndDate,
            preset: this.whichPeriod
          })
        }
      }
    }
  },
  data () {
    return {
      // even though rest endpoint returns startDate and endDate,
      // we'll use locally calculated dates so that we can display
      // something even if rest call fails.
      totalHours: 0,
      exceptionCount: 0,
      clockLogFailureCount: 0,
      // now will be updated each time we load data in order to refresh chart
      now: moment()
    }
  },
  watch: {
    payPeriodType () {
      this.load()
    },
    payPeriodStarts () {
      this.load()
    }
  },
  methods: {
    load () {
      this.showLoadingMask()

      this.now = moment.tz(this.timezone)

      return service
        .retrievePayPeriodActivity(
          this.payPeriodDateRange[0].format(MomentFormats.Iso8601Short),
          // backend expects end of range to be inclusive
          moment(this.payPeriodDateRange[1]).subtract(1, 'days').format(MomentFormats.Iso8601Short),
          this.showClockLogFailures)
        .then(payPeriodActivity => {
          Object.assign(this.$data, payPeriodActivity || {})
          this.hideMask()
        })
        .catch(error => {
          this.showErrorMask(error)
          return Promise.reject(error)
        })
    },
    refreshImpl () {
      return this.load()
    },
    refresh () {
      this.coordinateWithRefresh(() => this.load())
    },
    inflect (value, count, singular, plural) {
      return inflect(value, count, singular, plural)
    },
    round (value) {
      return _.round(value, 1)
    }
  }
}
</script>
<style lang="scss" scoped>

.panel {
  :deep(.panel-body) {
    // Prevent chart senselessly resizing when re-rendered.
    // https://github.com/chartjs/Chart.js/issues/11005
    canvas {
      width: 350px !important;
      height: 75px !important;

      @media screen and (max-width: 475px) {
        width: 275px !important;
        height: 60px !important;
      }
      @media screen and (max-width: 385px) {
        width: 200px !important;
        height: 50px !important;
      }
    }
  }

  // Normally, the chart is 350px wide (configured in template above).
  // But when screen width gets below 400px (e.g., phone), then the
  // chart overflows the card. So limit width to 100% in such a case.
  // :deep(.chartjs-render-monitor) {
  //   @media only screen and (max-width: 475px)  {
  //     width: 100% !important;
  //   }
  // }

  .pay-period-table {
    margin-top: 1.25rem;
    font-size: 0.80rem;
    color: #555;

    .row {
      margin-top: 0.3rem;
      text-align: right;

      > * {
        white-space: nowrap;
      }

      // change layout on smaller devices.
      // (see chartHeight() computed property above)
      @media only screen and (max-width: 400px)  {
        margin-top: 0.5rem;

        .summary {
          text-align: left;
        }

        .review, .print {
          text-align: center;
        }
      }

      .icon {
        margin-right: 5px;
      }

      // need to adjust column padding to fit according to need

      > div:first-child {
        padding-left: 0px;
        padding-right: 0px;
      }

      > div:nth-child(2) {
        padding-left: 5px;
        padding-right: 5px;
      }

      > div:nth-child(3) {
        padding-right: 0px;
      }
    }
  }
}

</style>
