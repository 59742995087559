<template>
  <b-modal
    v-bind="$attrs"
    no-trap
    title="Memorize Report"
    ok-title="Save"
    :ok-disabled="saveDisabled"
    :cancel-disabled="saving"
    hide-header-close
    no-close-on-backdrop
    centered
    @ok.prevent="save"
  >
    <form-group label="Name" :disabled="!!existingItem || saving">
      <b-form-input v-model="name" />
    </form-group>

    <form-group label="" v-if="canEditOrgSettings"  :disabled="!!existingItem || saving">
      <b-form-radio-group v-model="entireOrg">
        <b-form-radio :value="false">
          <font-awesome-icon icon="user" /> For yourself
        </b-form-radio>
        <b-form-radio :value="true">
          <font-awesome-icon icon="users" /> Share with organization
        </b-form-radio>
      </b-form-radio-group>
    </form-group>

    <div class="or-separator">-- OR --</div>

    <form-group label="Choose an existing memorized report to overwrite" :disabled="saving">
      <key-multiselect
        v-model="existingItemId"
        label="name"
        track-by="id"
        select-label=""
        deselect-label=""
        :options="memorizedReports"
        @fullValueChange="existingItem = $event"
      />
    </form-group>

    <spinner v-if="saving" />
    <div class="error-message" v-else-if="errorMessage">
      <font-awesome-icon icon="triangle-exclamation" class="icon" />
      {{ errorMessage }}
    </div>

  </b-modal>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { extractErrorMessage } from '@/utils/misc'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import Spinner from '@/components/Spinner.vue'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'MemorizeReportModal',
  setup () {
    const modalController = useModalController()
    return {
      showModal: modalController.show,
      hideModal: modalController.hide
    }
  },
  inheritAttrs: false,
  components: {
    KeyMultiselect,
    Spinner
  },
  data () {
    return {
      saving: false,
      errorMessage: null,
      name: null,
      entireOrg: null,
      existingItemId: null,
      existingItem: null
    }
  },
  props: {
    reportId: String,
    prefs: Object
  },
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    saveDisabled () {
      return !this.name || !_.isBoolean(this.entireOrg) || this.saving
    },
    creationObject () {
      return {
        name: this.name,
        reportId: this.reportId,
        prefs: this.prefs,
        entireOrg: this.entireOrg
      }
    },
    memorizedReports () {
      return this.$store.getters['memorizedReports/sortedItems']('name')
        .filter(r => r.reportId === this.reportId)
        .filter(r => !r.entireOrg || this.canEditOrgSettings)
    }
  },
  watch: {
    existingItem (item) {
      this.name = item?.name ?? null
      this.entireOrg = item?.entireOrg ?? false
    }
  },
  methods: {
    close () {
      this.hideModal()
    },
    save () {
      this.saving = true
      this.errorMessage = null

      this.$store.dispatch(`memorizedReports/${this.existingItem ? 'update' : 'create'}`, Object.assign({}, this.existingItem, this.creationObject))
        .then(() => this.close())
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.saving = false })
    }
  },
  created () {
    this.$store.dispatch('memorizedReports/load')
  },
  mounted () {
    if (!this.canEditOrgSettings) this.entireOrg = false
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_bootstrap-variables';

.custom-radio {
  margin: .5rem;
  margin-right: 1rem;
}

.error-message {
  color: map-get($theme-colors, danger);
  .icon {
    margin-right: .5rem;
  }

  &.item-error-message {
    margin-top: .5rem;
  }
}

.or-separator {
    margin: 10px 0;
    margin-left: 25%;
  }

</style>
