<template>
  <b-modal
    v-bind="$attrs"
    ref="modal"
    title="Format Preferences"
    ok-only
    no-trap
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <form-group label="Duration format" :label-cols="labelCols">
      <b-form-radio-group
        v-model="value.durationFormat"
        class="checkbox-group"
      >
        <b-form-radio value="minute">Minute</b-form-radio>
        <b-form-radio value="decimal">Decimal</b-form-radio>
        <b-form-radio value="hundredth">Hundredth</b-form-radio>
      </b-form-radio-group>
    </form-group>
  </b-modal>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'FormatPrefsModal',
  inheritAttrs: false,
  data () {
    return {
      labelCols: 4,
    }
  },
  props: {
    value: Object
  },
  created () {
    // Set defaults.
    const formatPrefs = this.$store.state.formatPreferences.originalData
    if (!this.value.durationFormat) this.value.durationFormat = formatPrefs.durationFormat
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_bootstrap-variables';

.custom-radio {
  margin: .5rem;
  margin-right: 1rem;
}

.error-message {
  color: map-get($theme-colors, danger);
  .icon {
    margin-right: .5rem;
  }

  &.item-error-message {
    margin-top: .5rem;
  }
}

</style>
